<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listMaterialReturn,
  modifyBillStatus,
  sumListMaterialReturn
} from "@/api/mes/bill/processBack";
export default {
  name: "processBack",
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: listMaterialReturn,
        getSummaryApi: sumListMaterialReturn,
        exportOption: {
          fastExportUrl:
            '/api/system/mes/bill/process/material/return/exportListMaterialReturn',
          exportName: '工序退料'
        },
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "日期",
            type: "daterange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a + ' 00:00:00', endBillDate: b + ' 23:59:59' }),
          },
          {
            keyIndex: 0,
            format: "daterange",
            type: "timeRange",
            defaultValue: "month",
            data: [
              "day",
              "yesterday",
              "week",
              "lastWeek",
              "month",
              "lastMonth",
            ],
          },
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'billNos', label: '单据编号' },
              { filter: 'handBillNos', label: '手工单据号' },
            ]
          },
          this.$select({
            key: "bill_status",
            option: {
              seniorSearch: true,
              filter: "includeBillStatus",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listProduceDept",
            option: {
              type: "remote",
              label: "生产工厂",
              filter: "produceDeptIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中的单据吗？",
            btnType: "dropdown",
            type: "success",
            other: [
              {
                click: "reverseAudit",
                label: "反审核",
                alertText: "确认要反审核选中的单据吗？",
              },
            ],
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "handBillNo",
            label: "手工单据号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billStatusName",
            label: "单据状态",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billLineCount",
            label: "单据明细行数",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "billQty",
            label: "数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billMoney",
            label: "金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      let billIds = [];
      switch (type) {
        case "add":
          this.$router.push({
            name: "processBackDetail",
          });
          break;
        case "update":
          this.$router.push({
            name: "processBackDetail",
            query: {
              billId: row.billId,
              type: "Update",
            },
          });
          break;
        case "audit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push({ billId: item.billId, billStatus: 2 });
          });
          if (billIds.length >= 1) {
            try {
              await modifyBillStatus(billIds);
              this.$message.success("审核成功");
              this.$refs.tablePage.getList();
            } catch (err) { }
          } else {
            this.$message.error("请选择未审核的单据");
          }
          break;
        case "reverseAudit":
          this.options.check.forEach((item) => {
            if (item.billStatus == "2") billIds.push({ billId: item.billId, billStatus: 0 });
          });
          if (billIds.length >= 1) {
            try {
              await modifyBillStatus(billIds);
              this.$message.success("反审核成功");
              this.$refs.tablePage.getList();
            } catch (err) { }
          } else {
            this.$message.error("请选择已审核的单据");
          }
          break;
        case "del":
          this.options.check.forEach((item) => {
            if (item.billStatus == "0") billIds.push({ billId: item.billId, billStatus: 3 });
          });
          if (billIds.length >= 1) {
            try {
              await modifyBillStatus(billIds);
              this.$message.success("删除成功");
              this.$refs.tablePage.getList();
            } catch (err) { }
          } else {
            this.$message.error("请选择未审核的单据");
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
