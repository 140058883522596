<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="120px">
          <el-form-item label="生产工厂" prop="produceDeptId">
            <SelectRemote v-model="form.produceDeptId" :option="
                  $select({
                    key: 'listProduceDept'
                  }).option
                " />
          </el-form-item>
          <el-form-item label="生产工序" prop="processId">
            <SelectRemote v-model="form.processId" :option="
                  $select({
                    key: 'listProcess'
                  }).option
                " />
          </el-form-item>
          <el-form-item label="生产车间" prop="workshopId">
            <SelectRemote v-model="form.workshopId" :option="
                  $select({
                    key: 'listWorkshop'
                  }).option
                " />
          </el-form-item>
          <el-form-item label="工序人员编号" prop="processManNo">
            <el-input maxlength="20" class="brandInput" v-model.trim="form.processManNo" autocomplete="off"
              placeholder="请输入工序人员编号"></el-input>
          </el-form-item>
          <el-form-item label="工序人员名称" prop="processManName">
            <el-input maxlength="40" class="brandInput" v-model.trim="form.processManName" autocomplete="off"
              placeholder="请输入工序人员名称"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortNo">
            <el-input-number v-model.trim="form.sortNo" controls-position="right" :min="1"
              :max="100000"></el-input-number>
          </el-form-item>
          <el-form-item label="备注">
            <el-input class="brandInput" v-model.trim="form.remark" autocomplete="off" type="textarea"
              placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import {
  listProcessMan,
  addProcessManReturnResp,
  delProcessMan,
  getProcessManByProcessManId,
  modifyProcessMan,
  revertProcessMan
} from "@/api/mes/base/processMan";
import TablePage from '@/components/tablePage' //表格页面组件
export default {
  name: 'processMan',
  components: { TablePage, CDialog, SelectRemote },
  data() {
    return {
      //表格选中内容
      multipleSelection: [],
      title: "新增工序人员", //控制新增修改弹框标题
      dialogLoading: false, //表单
      //新增、单独修改对话框
      dialogFormVisible: false,
      //对话框表单
      form: {
        sortNo: 1
      },
      //新增表单校验
      rules: {
        produceDeptId: [
          {
            required: true,
            message: "生产工厂不能为空",
            trigger: ["blur", "change"],
          }
        ],
        processId: [
          {
            required: true,
            message: "生产工序不能为空",
            trigger: ["blur", "change"],
          }
        ],
        workshopId: [
          {
            required: true,
            message: "生产车间不能为空",
            trigger: ["blur", "change"],
          }
        ],
        processManNo: [
          {
            required: true,
            message: "工序人员编号不能为空",
            trigger: ["blur", "change"],
          }
        ],
        processManName: [
          {
            required: true,
            message: "工序人员名称不能为空",
            trigger: ["blur", "change"],
          }
        ],
      },
      options: {
        mutiSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: '工序人员',
        getListApi: listProcessMan,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'processManId',
        //搜索
        search: [
          {
            type: 'filters',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'processManNos', label: '工序人员编号' },
              { filter: 'processManNames', label: '工序人员名称' },
              { filter: 'zjms', label: '助记码' },
            ]
          },
          this.$select({
            key: 'listProduceDept',
            option: {
              type: 'remote',
              label: '生产工厂',
              filter: 'produceDeptIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
          },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'handleDelete',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            // alertText: '确认要删除选中的品牌吗？',
            btnType: 'dropdown',
            trigger: 'click', // 要求
            other: [
              {
                label: '回收站',
                collectionDialog: {
                  restoreListApi: revertProcessMan,
                  dcollectionBody: {
                    delFlag: '1'
                  }
                }
              }
            ]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'processManNo',
            label: '工序人员编号',
            type: 'link',
            click: 'handleDetail',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'processManName',
            label: '工序人员名称',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'produceDeptNo',
            label: '工厂编号',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.produceDeptInfo?.produceDeptNo,
          },
          {
            prop: 'produceDeptName',
            label: '工厂名称',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.produceDeptInfo?.produceDeptName,
          },
           {
            prop: 'processNo',
            label: '工序编号',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.processInfo?.processNo,
          },
          {
            prop: 'processName',
            label: '工序名称',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.processInfo?.processName,
          },
          {
            prop: 'workshopNo',
            label: '车间编号',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.workshopInfo?.workshopNo,
          },
          {
            prop: 'workshopName',
            label: '车间名称',
            minWidth: 150,
            align: 'center',
            formatter: (v, row) => row?.workshopInfo?.workshopName,
          },
          {
            prop: 'zjm',
            label: '助记码',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'sortNo',
            label: '排序',
            minWidth: 100,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160,
            align: 'center'
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'add':
          this.handleAdd()
          break
        case 'handleDelete':
          this.multipleSelection = this.options.check.map(item => ({processManId:item.processManId}));
          this.handleDelete()
          break
        case 'handleDetail':
          this.handleDetail(row)
          break
        default:
          break
      }
    },
    // 新增按钮
    async handleAdd() {
      this.title = "新增工序人员";
      this.reset(); // 表单重置;
      this.dialogFormVisible = true;
    },
    //新增、修改对话框确认按钮
    async submit() {
      try {
        await this.$refs["ruleForm"].validate();
        if (!this.form.processManId) {
          await addProcessManReturnResp(this.form);
          this.$message({
            message: "新增工序人员成功~",
            type: "success",
          });
        } else {
          await modifyProcessMan(this.form);
          this.$message({
            message: "修改工序人员成功~",
            type: "success",
          });
        }
        this.cancel();
        this.$refs.tablePage.getList();
      } catch (error) { }
    },
    //新增取消
    cancel() {
      this.dialogFormVisible = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        sortNo: 1
      };
      this.resetForm("ruleForm");
    },
    // 删除按钮
    handleDelete() {
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${this.multipleSelection.length} </span>个工序人员吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      }).then(() => {
        return delProcessMan(this.multipleSelection); //删除请求
      })
        .then(() => {
          this.$refs.tablePage.getList(); //渲染列表
          this.$modal.msgSuccess("删除成功");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //  修改\点击编号
    async handleDetail(row) {
      this.title = "编辑工序人员";
      this.dialogFormVisible = true;
      this.dialogLoading = true;
      this.reset();
      getProcessManByProcessManId(row.processManId).then((response) => {
        this.form = response.data;
        this.dialogLoading = false;
      });
    },
  }
}
</script>

<style lang="scss" scoped></style>
