<template>
  <div>
    <TablePageDetail v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import { fcount, deepCopy } from "@/utils";
import {
  addProcessTask,
  getProcessManByProcessManId,
  modifyProcessTask,
  modifyBillStatus
} from "@/api/mes/bill/processTask";
export default {
  name: 'processTaskDetail',
  components: {
    TablePageDetail: () => import('@/components/tablePageDetail'),
  },
  data() {
    return {
      options: {
        buttons: {
          isImportOrderBtn: false,
        },
        topQuery: {
          isProcess:true
        },
        billType: 200102,
        rowKey: "billId",
        billId: this.$route.query.billId || '',
        getQuitPath: "/mes/bill/processTask",
        addApi: addProcessTask,
        detailApi: getProcessManByProcessManId,
        updeteApi: modifyProcessTask,
        updeteStatusApi: modifyBillStatus,
        form: {
          billStatus: "",
          details: [{}],
        },
        columns: [
          {
            prop: "goodsNo",
            label: "商品编码",
            type: 'remoteSelect',
            showItem: {
              optionId: "goodsId",
              optionName: "goodsNo",
              prop: 'goodsId',
              label: 'goodsNo',
            },
            option: this.$select({
              key: "listGoods",
              option: {
                option: {
                  // remoteBody: { },
                  change: (propValue, row, selectRow) => {
                    this.optionsList(selectRow.curTableIndex, [row], true);
                  },
                  tableChange: (propValue, row, selectRow) => {
                    this.optionsList(row.curTableIndex, row.check, false);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择商品",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: this.$dialog({
                          key: "goods",
                        }),
                      },
                    },
                  ],
                },
              },
            }).option,
            minWidth: 180,
            align: "center",
          },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 100,
            align: "center",
            type: 'localSelect',
            option: {
              rowDataKey: "units",
              label: "unitName",
              value: "unitId",
              filterable: true,
              change: (propValue, row, selectRow) => {
                this.numUnit(selectRow.curTableIndex, row);
              },
            },
            // formatter: (v, row) => row?.goodsInfo?.unitName,
          },
          {
            prop: "unitQty",
            label: "数量",
            minWidth: 100,
            align: "center",
            type: 'my-input',
            inputType: 'number',
          },
          {
            prop: "unitSalePrice",
            label: "售价",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "unitMoney",
            label: "金额",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 100,
            align: "center",
            type: 'my-input',
          },
        ],
        summary: ["unitQty", "unitMoney"]
      }
    };
  },
  methods: {
    optionsList(tableIndex, row, isLine = false) {
      row = row.map(v => ({ ...v, unitSalePrice: v.salePrice, unitPurPrice: v.purPrice, unitMoney: 0, lineType: 0 }))
      if (isLine) {
        this.$set(this.options.form.details, tableIndex, row[0])
      } else {
        this.options.form.details.splice(tableIndex, 0, ...row);
        this.options.form.details = this.options.form.details.filter(item => item.goodsId != undefined)
      }
    },
    numUnit(tableIndex, row) {
      let newVal = deepCopy(this.options.form.details[tableIndex])
      newVal = {
        ...newVal,
        unitId: row.unitId,
        unitName: row.unitName,
        unitSalePrice: row.salePrice,
        unitPurPrice: row.purPrice,
        unitMoney: fcount([row.salePrice, (newVal.unitQty || 0)], '*'),
      }
      this.$set(this.options.form.details, tableIndex, newVal)
    },
    async handleEvent(type, row, rowIndex) {
      switch (type) {
        case "inputChange":
          if (row.prop == 'unitQty') {
            let rowNew = this.options.form.details[rowIndex]
            let unitMoney = fcount([rowNew.unitSalePrice, rowNew.unitQty], '*')
            this.$set(rowNew, 'unitMoney', unitMoney)
          }
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
