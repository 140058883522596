var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("c-dialog", {
        attrs: {
          title: _vm.title,
          width: 500,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dialogLoading,
                        expression: "dialogLoading",
                      },
                    ],
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产工厂", prop: "produceDeptId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                            }).option,
                          },
                          model: {
                            value: _vm.form.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "produceDeptId", $$v)
                            },
                            expression: "form.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车间编号", prop: "workshopNo" } },
                      [
                        _c("el-input", {
                          staticClass: "brandInput",
                          attrs: {
                            maxlength: "20",
                            autocomplete: "off",
                            placeholder: "请输入车间编号",
                          },
                          model: {
                            value: _vm.form.workshopNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "workshopNo",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.workshopNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车间名称", prop: "workshopName" } },
                      [
                        _c("el-input", {
                          staticClass: "brandInput",
                          attrs: {
                            maxlength: "40",
                            autocomplete: "off",
                            placeholder: "请输入车间名称",
                          },
                          model: {
                            value: _vm.form.workshopName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "workshopName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.workshopName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "排序", prop: "sortNo" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            "controls-position": "right",
                            min: 1,
                            max: 100000,
                          },
                          model: {
                            value: _vm.form.sortNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "sortNo",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.sortNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticClass: "brandInput",
                          attrs: {
                            autocomplete: "off",
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 2, maxRows: 4 },
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "remark",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }