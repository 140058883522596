var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("CDialog", {
        attrs: {
          title: "批量修改仓库",
          width: 500,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库仓库", prop: "inStoreId" } },
                      [
                        _c("SelectRemote", {
                          attrs: {
                            option: _vm.$select({
                              key: "listProduceDept",
                            }).option,
                          },
                          model: {
                            value: _vm.form.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "inStoreId", $$v)
                            },
                            expression: "form.inStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submit } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }